.editor-wrapper-class {
  color: #fff;
  display:flex;
  flex-direction: column;
  height: 100%;
}

.editor-class {
  padding: 0px 9px;
  order:1;
  height: 370px !important;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(236, 15, 105, 1);
  }

  .DraftEditor-root {
    
    .rdw-suggestion-dropdown {
      left: -10px !important;
      width: calc(100vw - 30px);
      color: rgba(63, 62, 60, 1);
      .rdw-suggestion-option {
        background-color: #E5E4E4;
        border: 1px solid rgba(205, 205, 205, 1);
        display: flex;
        flex-direction: row;
      }
    }

    .rdw-mention-link {
      background-color: transparent;
      color: rgba(236, 15, 105, 1);
      font-weight: 700;
    }

    .public-DraftStyleDefault-block {
      margin: 0.4em 0 !important;
    }
  }
}

.toolbar-class {
  order:2;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  background-color:transparent !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top: 2px solid #BDBDBD !important;
  .rdw-inline-wrapper {
    margin-left:auto;
    margin-right: -10px;
    .rdw-option-wrapper {
      background-color: transparent;
      border: 1px solid rgba(236, 15, 105, 1);
      width: 26px;
      height: 26px;

      &.rdw-option-active {
        box-shadow: none;
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .editor-class {
    height: 100% !important; 
  }
}