.rich-editor-container {
  border:1px solid transparent;
  height: 100%;
  padding-bottom: 0px;
  
  &:focus-within {
    margin-bottom: 30px;
    .character-count {
      visibility: visible;
    }
  }
}

.editor-container {
  border-radius: 0px;
  max-width: 100%;
  
  height: calc(100% - 50px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0,0,0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(236, 15, 105, 1);
  }

  &.no-toolbar {
    height: 100%;
  }

  color: #fff;
  position: relative;
  line-height: 18px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'ProximaNovaRegular', sans-serif;
  text-align: left;
}

.editor-inner {
  background: transparent;
  position: relative;
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 26px;
  margin-right:4px;
}

.editor-bottom-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .character-count {
    padding-top:10px;
    padding-left:10px;
    font-family: 'ProximaNovaBold';
    font-size: 13px;
    line-height: 15px;
    text-align: left;
    color: rgba(112,112,112,1);
    transition-property: all;
    transition-duration: .3s;
    /* visibility: hidden; */
  }
}

@media (max-width: 768px) {
  .rich-editor-container {
    padding-bottom: 10px;
  }
  .editor-bottom-bar {
    flex-direction: column;
  }
}

.editor-input {
  resize: none;
  font-size: 15px;
  position: relative;
  tab-size: 1;
  outline: 0;
  height: 100%;

  &::selection {
    background-color: rgba(236, 15, 105, .2);
  }  

  a {
    color: rgba(236, 15, 105, 1);
    &:hover {
      color: rgba(236, 15, 105, 1);
    }
  }
}

.editor-placeholder {
  color: rgba(130,130,130,1); 
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 8px;
  left: 10px;
  font-size: 14px;
  -webkit-user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
    font-family: 'ProximaNovaBold';
    font-weight: normal;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  color: rgba(236, 15, 105, 1);
  text-decoration: none;
}


.editor-tokenComment {
  color: slategray;
}

.editor-tokenPunctuation {
  color: #999;
}

.editor-tokenProperty {
  color: #905;
}

.editor-tokenSelector {
  color: #690;
}

.editor-tokenOperator {
  color: #9a6e3a;
}

.editor-tokenAttr {
  color: #07a;
}

.editor-tokenVariable {
  color: #e90;
}

.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
  * {
    word-break: break-word;
  }
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left-color: rgb(206, 208, 212);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 32px 8px 32px;
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.emoji {
  color: transparent;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: 0 -1px;
}

.emoji-inner {
  padding: 0 0.15em;
}

.emoji-inner::selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji-inner::moz-selection {
  color: transparent;
  background-color: rgba(150, 150, 150, 0.4);
}

.emoji.happysmile {
  background-image: url(./images/emoji/1F642.png);
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
  background: transparent;
  padding: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  vertical-align: middle;
}

.toolbar button.toolbar-item {
  width: 26px;
  height: 26px;
  border:1px solid rgba(236, 15, 105, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left:5px;
  background-color: transparent;

  &:hover {
    background-color: rgba(236, 15, 105, .4);
  }
}

.toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.toolbar button.toolbar-item.image-upload {
  position: relative;
}

.toolbar button.toolbar-item.image-upload input[type="file"] {
  display:block;
  width: 100%;
  position: absolute;
  opacity: 0;
}

.toolbar button.toolbar-item.spaced {
  margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
}

.toolbar button.toolbar-item:disabled i.format {
  opacity: 0.2;
}

.toolbar button.toolbar-item.active {
  background-color: transparent;
}

.toolbar button.toolbar-item.active i {
  opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: transparent;
}

.toolbar .divider {
}

.toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  width: 200px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  --webkit-user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.toolbar i.chevron-down {
  margin-top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  --webkit-user-select: none;
}

.toolbar i.chevron-down.inside {
  width: 16px;
  height: 16px;
  display: flex;
  margin-left: -25px;
  margin-top: 11px;
  margin-right: 10px;
  pointer-events: none;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-image: url(images/icons/chevron-down.svg);
}

#block-controls button:hover {
  background-color: #efefef;
}

#block-controls button:focus-visible {
  border-color: blue;
}

#block-controls span.block-type {
  background-size: contain;
  display: block;
  width: 18px;
  height: 18px;
  margin: 2px;
}

#block-controls span.block-type.paragraph {
  background-image: url(images/icons/text-paragraph.svg);
}

#block-controls span.block-type.h1 {
  background-image: url(images/icons/type-h1.svg);
}

#block-controls span.block-type.h2 {
  background-image: url(images/icons/type-h2.svg);
}

#block-controls span.block-type.quote {
  background-image: url(images/icons/chat-square-quote.svg);
}

#block-controls span.block-type.ul {
  background-image: url(images/icons/list-ul.svg);
}

#block-controls span.block-type.ol {
  background-image: url(images/icons/list-ol.svg);
}

#block-controls span.block-type.code {
  background-image: url(images/icons/code.svg);
}

.dropdown {
  z-index: 5;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  min-width: 268px;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  width: 200px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  --webkit-user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
}

.link-editor {
  position: absolute;
  z-index: 1000;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  max-width: 300px;
  width: 100%;
  opacity: 0;
  background-color: #fff;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  transition: opacity 0.5s;
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 24px);
  box-sizing: border-box;
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: rgb(5, 5, 5);
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
}

.link-editor div.link-edit {
  background-image: url(images/icons/pencil-fill.svg);
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link-editor .link-input a {
  color: rgb(33, 111, 219);
  text-decoration: none;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
  text-decoration: underline;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

i.undo {
  background-image: url(images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(images/icons/arrow-clockwise.svg);
}

.icon.paragraph {
  background-image: url(images/icons/text-paragraph.svg);
}

.icon.large-heading,
.icon.h1 {
  background-image: url(images/icons/type-h1.svg);
}

.icon.small-heading,
.icon.h2 {
  background-image: url(images/icons/type-h2.svg);
}

.icon.bullet-list,
.icon.ul {
  background-image: url(images/icons/list-ul.svg);
}

.icon.numbered-list,
.icon.ol {
  background-image: url(images/icons/list-ol.svg);
}

.icon.quote {
  background-image: url(images/icons/chat-square-quote.svg);
}

.icon.code {
  background-image: url(images/icons/code.svg);
}

i.bold {
  background-image: url(images/icons/type-bold.svg);
}

i.italic {
  background-image: url(images/icons/type-italic.svg);
}

i.underline {
  background-image: url(images/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(images/icons/type-strikethrough.svg);
}

i.code {
  background-image: url(images/icons/code.svg);
}

i.link {
  background-image: url(images/icons/link.svg);
}

i.left-align {
  background-image: url(images/icons/text-left.svg);
}

i.center-align {
  background-image: url(images/icons/text-center.svg);
}

i.right-align {
  background-image: url(images/icons/text-right.svg);
}

i.justify-align {
  background-image: url(images/icons/justify.svg);
}

#typeahead-menu {
  position: fixed;
  z-index: 1122;
}

.mention {
  color: rgba(236, 15, 105, 1);
  cursor: pointer;
  &:before {
    content: '@';
  }
}

.mentions-menu {
  width: 300px !important;
  position: relative;
  top: 30px;
  box-shadow: 0px 0px 5px 3px rgba(51, 51, 51, 0.25);
}


.mentions-menu ul li {
  display:flex;
  flex-direction: row;
  align-items: center;
  height:56px;
  padding: 8px 15px;
  border-bottom: 1px solid rgba(205, 205, 205, 1);
  background-color: rgb(229,228,228);

  &:hover {
    background : none;
    background-color: rgba(236, 15, 105, 0.5);
    &>span.text {
      color: rgba(236, 15, 105, 1);
    }
    &>span.account-type {
      &>img:first-child {
        visibility: hidden;
      }
      &>img:last-child {
        visibility: visible;
      }
    }
  }
}

.mentions-menu ul li>div.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}

.mentions-menu ul li>div.avatar>img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mentions-menu ul li>span.account-type {
  margin-left:4px;
  margin-right:10px;
  width:32px;
  position: relative;

  &>img {
    display: block;
    width:100%;
  }
  &>img:first-child {
    visibility: visible;
  }
  &>img:last-child {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.mentions-menu ul li>span.text {
  font-size: 14px;
  line-height: 14px;
  font-family: 'ProximaNovaBold';
  display: block;
  flex-grow: 0;
  text-overflow: ellipsis;
  width: 180px;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.mention:focus {
  /* box-shadow: rgb(180,213,255) 0px 0px 0px 2px; */
  outline: none;
}

.typeahead-popover {
  background:#E5E4E4;
  width: auto;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  overflow: hidden;
  max-height: 237px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.typeahead-popover ul li.selected {
  
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  
}

.typeahead-popover li:last-child {
  
}

.typeahead-popover li:hover {
  
}

.typeahead-popover li .text {
}